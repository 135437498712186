<template>
  <div class="home">
    <reset-password>
      <v-icon slot="eye-icon" name="eye"></v-icon>
      <v-icon slot="eye-icon-closed" name="eye-off"></v-icon>
    </reset-password>
  </div>
</template>

<script>
export default {
  name: 'ResetPwdView',
  methods: {},
};
</script>
